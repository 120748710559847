import type { ReactNode } from "react";
import type { Action } from "../components/Action.tsx";
import { EditableButton, type EditableButtonProps } from "../components/forms/EditableButton.tsx";
import { Missing } from "./AdminTable.tsx";

export interface AdminLinkButtonProps extends Omit<EditableButtonProps, "label" | "action"> {
    label: ReactNode;
    isMissing?: boolean;
    action: Action | undefined;
}

export function AdminLinkButton({ label, action, isMissing, ...buttonProps }: AdminLinkButtonProps) {
    return isMissing ? (
        <Missing />
    ) : (
        <EditableButton className="text-left" inAdmin icon="jp-preview-icon" action={action} {...buttonProps}>
            {label}
        </EditableButton>
    );
}
