import C from "./AdminTable.module.css";

import type { ComponentProps, ReactNode } from "react";
import { mcn } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";

export interface WAdminTableProps extends ComponentProps<"table"> {
    /** Remove the responsive wrapper, suitable for using a sticky header in a parent scrolling area. */
    sticky?: boolean;
    children: ReactNode;
}

export function WAdminTable({ children, sticky, ...props }: WAdminTableProps) {
    const table = <table {...mcn(cn(C.AdminTable), props)}>{children}</table>;

    return sticky ? table : <div className={C.tableContainer}>{table}</div>;
}
