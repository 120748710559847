import { useSingleLocationSuspense } from "../../modules/location/query.ts";

export function LocationItem({ value }: { value: string }) {
    const location = useSingleLocationSuspense(value);
    return (
        <div>
            {location?.display_name} ({value})
        </div>
    );
}
