import type { ReactNode } from "react";
import type { ColumnFilterInputType } from "./graphql.gen/graphql.ts";

/** Meta-properties about a field belonging to a {@link TItem}, which has the type {@link TValue}. */
export interface FieldConfig<TItem, TValue> {
    get: (obj: TItem) => TValue;
    render?: React.FC<{ value: TValue; item: TItem }>;

    title: ReactNode;
    editTitle?: ReactNode;
    copyable?: boolean;
    inTable?: boolean;
    align?: "text-left" | "text-center" | "text-right";

    cellClassName?: string;
}

export interface IdFieldConfig<TItem, TValue> extends FieldConfig<TItem, TValue> {
    id: string;
}

export interface GqlFieldConfig<TItem = unknown, TOrderFields extends string = string, TValue = unknown>
    extends IdFieldConfig<TItem, TValue> {
    order?: TOrderFields;
    hidden?: boolean;
    filter?: FilterType;
}

/** Convenience type for field definitions.
 *
 * @example const myFields = { ... } satisfies FieldsOf<MyType>;
 */
export type FieldsOf<TItem> = Record<string, FieldConfig<TItem, any>>;

export type GqlFieldsOf<TItem, TOrderFields extends string> = GqlFieldConfig<TItem, TOrderFields, any>[];

//#region Filter types
export interface GqFilter {
    type?: ColumnFilterInputType;
    value?: string;
    value2?: string;
    values?: string[];
}

export interface GqFilterParams<TOrderFields extends string = string> extends GqFilter {
    field: TOrderFields;
}

export type FilterType = React.FC;
//#endregion

/** Utility function to automatically infer types for a {@link FieldConfig} */
export function field<TItem, TValue>(
    title: ReactNode,
    get: (obj: TItem) => TValue,
    options?: Partial<FieldConfig<TItem, TValue>>,
): FieldConfig<TItem, TValue> {
    return { title, get, ...options };
}

/** Utility function to infer types for a {@link GqlFieldConfig}.
 *
 * (otherwise TypeScript gets confused when different types of fields are mixed in a single object.)
 */
export function gf<TItem, TOrderFields extends string, TValue>(options: GqlFieldConfig<TItem, TOrderFields, TValue>) {
    return options;
}

/** "Map" over a field config, transforming the object it operates on. */
export function mapField<TNewItem, TItem, TValue>(
    { render, get, ...field }: FieldConfig<TItem, TValue>,
    mapFn: (obj: TNewItem) => TItem,
    options?: Partial<FieldConfig<TNewItem, TValue>>,
): FieldConfig<TNewItem, TValue> {
    return {
        ...field,
        get: (obj: TNewItem): TValue => get(mapFn(obj)),
        render: render ? ({ value, item }) => render({ value, item: mapFn(item) }) : undefined,
        ...options,
    };
}
